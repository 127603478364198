.search-bar {
  position: relative;
  z-index: 0;
  padding: 5px 60px 7px 24px;
  border-radius: 999px;
  input{
    font-weight: 700;
    font-size: 16px;
    outline: none;
    &::placeholder {
      color: var(--bs-primary);
      font-weight: 800;
    }
  }
  .icon{
    background: url(../../../images/png/search-1.png) center no-repeat;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 10px;
    right: 36px;
  }
}