// custom fonts 

@font-face {
    font-family: 'Fellix-Medium';
    src: url('../../fonts/fellix-trail/regular/FellixTRIAL-Medium.otf')
}

@font-face {
    font-family: 'Fellix-Bold';
    src: url('../../fonts/fellix-trail/regular/FellixTRIAL-Bold.otf')
}

@font-face {
    font-family: 'Fellix-Regular';
    src: url('../../fonts/fellix-trail/regular/FellixTRIAL-Regular.otf')
}

@font-face {
    font-family: 'Fellix-Light';
    src: url('../../fonts/fellix-trail/regular/FellixTRIAL-Light.otf')
}

@font-face {
    font-family: 'Fellix-Thin';
    src: url('../../fonts/fellix-trail/regular/FellixTRIAL-Thin.otf')
}