/* starts, person-selector css */
.tip-card {
  cursor: pointer;
  margin: 24px 0 0 0;
  .title,
  .info {
    border-radius: 16px;
    padding: 6px 12px;
    line-height: 1;
    //white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .title {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: 27px;
    display: flex;
    align-items: center;
    .name {
      width: 100%;
    }
  }
  .info {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    text-transform: lowercase;
    letter-spacing: 1px;
    padding-bottom: 10px;
  }
  .img {
    height: 100px;
    background: rgb(191, 191, 191);
    img {
      max-width: 100%;
      min-width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }
}
/* end, person-selector css */
