//
// Check/radio
//

.form-switch {
    padding-left: 0;
    .form-check-input {
        background-image: none;
        width: 18px;
        height: 18px;
        border: 1px solid $primary;
        margin-right: 12px;
        margin-left: 0;
        &:checked{
            background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
        }
    }
    .form-check-label {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
}