// (L.no : 6 ) Color system
$purple:#9600FF;
$purpleLight:#F3E3FF;
$spring-green:#00FFB1;

// (L.no : 63 ) 
$primary: $purple ;
$secondary: $spring-green ;
$light: $purpleLight ;

// (L.no : 233 ) Spacing
$spacer: 1rem ;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  -20: 20px,
  -24: 24px,
  -34: 34px,
  -40: 40px,
) ;

// Body
//
// (L.no : 277 ) Settings for the `<body>` element.

$body-color:                $purple;

// (L.no : 373 ) scss-docs-start border-radius-variables
$border-radius: 0.9375rem;

// (L.no : 395 ) stylelint-disable value-keyword-case 
$font-family-sans-serif: "Fellix-Regular" !important;

// (L.no : 395 ) scss-docs-start btn-variables
$nav-link-padding-y: 0.5625rem;

// (L.no : 425 ) $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// (L.no : 425 ) $font-size-base effects the font size of the body text
$font-size-base: 1.125rem; // Assumes the browser default, typically `16px`
$line-height-base: 1.375;

// (L.no : 584 ) scss-docs-start input-btn-variables
$input-btn-padding-y: 0.272rem;

// (L.no : 678 ) scss-docs-start form-input-variables
$input-bg: transparent;
$input-color: $primary;
$input-focus-border-color: transparent;
$input-focus-box-shadow: none;
$input-placeholder-color: rgba(40, 26, 200, 0.35);

// (L.no : 781 ) scss-docs-start input-group-variables
$input-group-addon-bg: transparent;
$input-group-addon-border-color: $primary;

