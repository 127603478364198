//default
$breakpoint-lg : 992px;
@mixin media-breakpoint-lg {
    @media (min-width: $breakpoint-lg) {
        @content;
    }
}

$breakpoint-xxl : 1400px;
@mixin media-breakpoint-xxl {
    @media (min-width: $breakpoint-xxl) {
        @content;
    }
}