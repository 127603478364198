/* starts, _enter-tip scss */
.card {
  &.enter-tip {
    @include media-breakpoint-up(lg) {
      width: 450px;
    }
    @extend .mx-lg-auto;
    position: relative;
    z-index: 1;
    border: 0;
    box-shadow: 10px 10px 30px 10px rgba(0, 0, 0, 0.5);
    border-radius: 40px 40px 0px 0px;
    margin-top: -38px;
    padding: 0 19px 60px 19px;

    .user {
      position: relative;
      z-index: 0;
      min-height: 99px;
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
      padding: 14px 20px;
      .text{
        max-width: calc(100% - 99px - 20px);
      }
      img,
      span {
        position: absolute;
        top: -26px;
        right: 19px;
        width: 97px;
        height: 97px;
        border-radius: 50%;
        z-index: 1;
      }
      img{
        object-fit: cover;
      }
      span {
        &.bg {
          display: none;
          width: 99px;
          height: 100px;
          background-color: #c4c4c4;
          z-index: 0;
        }
      }
    }
    .nav-pills {
      .nav-link {
        font-size: 15px;
        line-height: 1.2;
      }
    }
    .tab-content {
      padding: 0 14px;
      .choose-amount {
        div[class*="col-"] {
          position: relative;
          z-index: 0;
        }
        input[type="radio"] {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-color: transparent;
          background-color: transparent;
          cursor: pointer;
          width: 100%;
          height: 100%;
          opacity: 0;
          + div {
            border: 1px solid $primary;
            box-sizing: border-box;
            border-radius: 15px;
            font-size: 20px;
            line-height: 26px;
            padding: 4px 8px;
            min-width: 70px;
            letter-spacing: -1px;
            &.custom {
              min-width: 217px;
            }
          }
          &:checked + div {
            @extend .bg-primary;
            @extend .text-white;
          }
        }
        .or {
          padding: 8px 6px 0 0;
          min-width: 64px;
        }
      }
    }
    .feild-tip {
      width: 100%;
      min-height: 67px;
      position: relative;
      z-index: 0;
      padding: 18px 14px 20px 89px;
      label,
      small {
        position: absolute;
      }
      label {
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        left: 14px;
      }
      small {
        font-weight: bold;
        font-size: 8px;
        line-height: 10px;
        right: 28px;
        bottom: 9px;
      }
      input[type="number"] {
        border: 0;
        border-bottom: 1px solid $primary;
        background-color: transparent;
        color: $primary;
        //min-width: 161px;
        width: 95%;
      }
    }
  }
}
/* end, _enter-tip scss */
