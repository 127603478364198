//
// Custom Input Group styles
//


//
// Base styles
//

.input-group {
  border: 1px solid $primary;
  box-sizing: border-box;
  border-radius: 15px;
}

// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
  border-width: 0 0 0 1px;
  border-radius: 0;
  path{
    fill: $input-group-addon-color;
  }
}