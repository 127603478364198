/* starts, component Not Found */
.not-found {
    padding: 32px;
    z-index: 0;
    min-height: 100vh;
    @include media-breakpoint-lg{
        padding: 80px;
    }
    .shape-img{
        left: -132px;
        top: 51px;
        display: none;
        @include media-breakpoint-lg{
            display: block;
        }
    }
    h1{
        @include media-breakpoint-xxl{
            font-size: 120px;
        }
    }
}
/* end, components not-found */
