/* starts, components group form */
.group-form {
    .another-member {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        min-height: 67px;
        position: relative;
        z-index: 0;
        padding: 2px 14px 18px 14px;
        align-items: center;
        line-height: 1;
        .title {
            font-weight: 500;
            font-size: 25px;
            line-height: 1;
            position: relative;
            z-index: 0;
            top: -2px;
            &.donate-label {
                font-size: 20px;
            }
        }
        .name {
            line-height: 1;
            padding: 4px 0;
            span{
                padding-right: 10px;
            }
        }
    }
}
/* end, components group form */
