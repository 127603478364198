/* starts, enter card details css */
.payment-success {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .title {
        font-size: 18px;
        line-height: 21.6px;
        padding: 14px 20px;
        font-weight: 700;
    }
    .total {
        flex-grow: 1 !important;
        padding: 0 20px;
        .amount {
            min-height: 142px;
            position: relative;
            z-index: 0;
            padding: 53px 40px 20px 86px;
            background-color: #FBFBFB;
            width: 100%;
            label { 
                font-weight: 600;
                font-size: 16px;
                line-height: 30px;
                left: 28px;
                position: absolute;
            }
            .number {
                border-bottom: 1px solid $spring-green;
                color: $primary;
                width: 100%;
                display: inline-block;
                padding: 0 0 3px 20px;
                font-weight: 600;
                font-size: 20px;
            }
        }
    }
    .thanx {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        svg {
            width: 16px;
            margin-left: 6px;
        }
    }
    .get-started {
        text-align: center;
        padding: 18px 0 28px 0;
        a {
            text-decoration: none;
        }
    }
    .buttons {
        text-align: center;
        padding-bottom: 12px;
    }
}
/* end, enter card details css */
