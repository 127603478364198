/* starts, payment person */
.person{
    .tab-border {
        position: relative;
        z-index: 0;
        &::after{
            position: absolute;
            top:6px;
            left:0;
            right:0;
            bottom:0;
            border:1px solid var(--bs-primary);
            border-radius: 16px;
            content: "";
            z-index: -1;
        }
        .bill{
            &.row{
                margin-left: 0;
                margin-right: 0;
            }
        }
      }
}
/* end, payment person */