/* custom css */

/* starts, google + apple pay button */
.google-pay-button,
.apple-pay-button {
  border: 0 solid #9600ff;
  background-color: #000;
  width: 100%;
  border-radius: 24px;
  padding: 0;
  margin: 0 0 0 0;
}
.google-pay-button svg,
.apple-pay-button svg {
  width: 80px;
  height: 36px;
}
.google-pay-button svg g#Pay path,
.google-pay-button svg polygon {
  fill: #fff;
}

.apple-pay-button svg .st0 {
  fill: none;
}
.apple-pay-button svg #Logo,
.apple-pay-button svg #Pay {
  fill: #fff;
}
/* ends, google + apple pay button */

/* starts, Payment form loading */
.payment-form-loading-overlay {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
/* end, Payment form loading */

/*** Not Found Page Styles ***/
.notFound-container {
  position: relative;
  display: grid;
  place-content: center;
  justify-items: center;
  text-align: center;
  gap: 1rem;
  color: white;
  background: #9600ff;
}

.notFound-container h4 {
  color: lightgrey;
  font-size: 28;
}

.notFound-img {
  width: 55%;
}

.shape-img {
  position: absolute;
  right: 80%;
  top: 10%;
  background: #00ffb1;
  width: 450px;
  height: 130px;
  border: none;
  border-radius: 999px;
  transform: rotate(-45deg);
}

.shape-img h2 {
  color: #9600ff;
  margin-top: 2.2rem;
  transform: rotate(45deg);
  font-weight: bold;
}

.home-button {
  background: none;
  border: 3px solid #00ffb1;
  border-radius: 999px;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  height: 50px;
  margin: 20px 20px 0 20px;
  font-size: 16px;
  width: 120px;
}

/*** Delete arrows within inputs ***/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*** Removes outline on inputs ***/
.input {
  outline: 0;
  margin-top: 0.13rem;
  margin-left: 0.4rem;
}

/*** Loading Page Styles ***/
.loading-page {
  display: grid;
  text-align: center;
  height: 100vh;
  place-content: center;
  justify-items: center;
}

.loading-page img {
  opacity: 0.8;
}

/*** Google Pay and Apple Pay Button ***/
.google-pay-button:disabled,
.apple-pay-button:disabled {
  opacity: 0.4;
}

.google-pay-loading,
.apple-pay-loading {
  display: flex;
  justify-content: center;
}

.google-pay-loading,
.apple-pay-loading svg {
  margin-left: 1.5rem;
}

.loading-gif {
  width: 8%;
  transform: translateX(8rem);
}

/*** Pay Button fee amount***/

.fee-amount span {
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.1rem;
}

@media only screen and (max-width: 768px) {
  .notFound-container h4 {
    padding: 0 0.5rem;
  }
  .notFound-img {
    width: 85%;
  }
}

.input-tip {
  margin-left: 15px;
}

#input-tip-inline {
  border: 1px solid #9600ff;
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 20px;
  line-height: 26px;
  padding: 4px 8px;
  letter-spacing: -1px;
  color: #9600ff;
}

.input-tip-custom {
  min-width: 200px !important;
  width: 200px !important;
}

/*** PaymentOptions tab content styles ***/