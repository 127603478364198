@import "custom/fonts";
@import "custom/variables";
@import "../plugins/bootstrap-5.0.1/scss/bootstrap";

// mixins
@import "mixins/breakpoint";

// Layout & components
@import "custom/forms";
@import "custom/buttons";

// app
@import "custom/common";

@import "custom/header";
@import "custom/widgets/enter-tip";
@import "custom/widgets/enter-card-details";
@import "custom/widgets/payment-success";
@import "custom/widgets/person-selector";
@import "custom/widgets/tip-card";

// payment
@import "components/group-form";
@import "components/tip-button";
@import "components/not-found";

// payment
@import "payment/person";
